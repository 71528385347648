import { computed, getCurrentInstance } from '@nuxtjs/composition-api';

const useGiftList = (props) => {
  const { emit } = getCurrentInstance();

  const items = computed(() => props.giftList.filter(item => String(item.id) !== String(props.currentGiftId)));

  const handlerAddToBasket = (payload) => {
    emit('addToBasketGift', payload);
  };

  return {
    items,
    handlerAddToBasket,
  };
};

export default useGiftList;
