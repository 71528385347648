<template>
  <div class="product-item-gift-mobile">
    <div class="product-item-gift-mobile__image-holder">
      <app-link
        class="product-item-gift-mobile__image-link"
        :href="productLink"
        blank
      >
        <app-picture
          class-picture="product-item-gift-mobile__picture"
          :alt="`Купить ${productName} — Фото №1`"
          class-img="product-item-gift-mobile__image"
          :fallback-image="productImageFallback"
          :item="productPicture"
        />
      </app-link>
    </div>
    <div class="product-item-gift-mobile__body-holder">
      <app-link
        class="product-item-gift-mobile__name"
        :href="productLink"
        blank
      >
        {{ productName }}
      </app-link>
      <div class="product-item-gift-mobile__buttons-holder">
        <button-control
          size="small"
          type="secondary"
          is-block
          @click="handlerAddToBasketGift"
        >
          Выбрать
        </button-control>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { ButtonControl } from '@book24/ui-components';

import { pictures } from '@/utils/formatters/formattedProduct';
import { productImage } from '@/utils/constants/catalogConstants';

import AppPicture from '@/components/AppPicture';

export default defineComponent({
  name: 'ProductGift',
  components: {
    AppPicture,
    ButtonControl,
  },
  props: {
    productId: { type: [String, Number], required: true },
    productName: { type: String, required: true },
    productCode: { type: String, required: true },
    picture: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const productLink = computed(() => `/product/${props.productCode}-${props.productId}/`);

    const productPicture = computed(() => pictures(props.picture));

    const handlerAddToBasketGift = () => {
      emit('addToBasketGift', { productId: props.productId });
    };

    return {
      productLink,
      productPicture,
      productImageFallback: productImage,
      handlerAddToBasketGift,
    };
  },
});
</script>

<style lang="less">
.product-item-gift-mobile {
  display: flex;
  padding: @size-x4;

  &__body-holder {
    flex: auto;
  }

  &__image-holder {
    margin-right: @size-x4;
    position: relative;
    flex: 0 0 90px;
  }

  &__image-link {
    display: flex;
    height: 100%;
    max-width: 100%;
  }

  &__picture {
    display: flex;
    align-items: flex-start;
    height: 100%;
    width: 100%;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__name {
    .p();
    .overflow-ellipsis-lines();

    margin: 0 0 auto;
    max-height: 48px;
    text-decoration: none;
    color: @black;
    transition: @A color;

    &._tap {
      color: @link;
    }
  }

  &__buttons-holder {
    margin-top: @size-x4;
    display: flex;
    max-width: 150px;
  }
}
</style>
