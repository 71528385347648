<template>
  <div class="gift-list-mobile">
    <h2 class="gift-list-mobile__title">
      Выберите один из этих товаров в подарок
    </h2>
    <ul class="gift-list-mobile__list">
      <li
        v-for="item in items"
        :key="item.id"
        class="gift-list-mobile__item"
      >
        <product-gift
          class="gift-list-mobile__product"
          :product-id="item.product.id"
          :product-name="item.product.name"
          :product-code="item.product.code"
          :picture="item.product.picture"
          @addToBasketGift="handlerAddToBasket"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import useGiftList from '@/modules/basket/compositions/useGiftList';

import ProductGift from '@/modules/basket/components/_mobile/GiftList/ProductGift/index.vue';

export default defineComponent({
  name: 'GiftList',
  components: {
    ProductGift,
  },
  props: {
    currentGiftId: { type: [String, Number], required: true },
    giftList: { type: Array, default: () => ([]) },
  },
  setup(props) {
    const giftList = useGiftList(props);
    return { ...giftList };
  },
});
</script>

<style lang="less">
.gift-list-mobile {
  width: 100%;
  box-sizing: border-box;

  &__title {
    .h-reset();
    .h3-mobile();

    padding: 0 @size-x4;
    margin-bottom: @main-size;
  }

  &__list {
    .list-reset();
  }

  &__item:not(:first-child) {
    border-top: 1px solid @gray-super-light;
  }
}
</style>
